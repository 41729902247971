import * as React from "react";

import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import styled from "@emotion/styled";
import { css } from "@emotion/react";

import "@fontsource/titillium-web";
import "@fontsource/roboto-mono";
import "@fontsource/share-tech-mono";

const logo = css`
  font-size: 24px;
  font-weight: 700;
  text-shadow: 1px 1px 5px #fff;
  margin-bottom: 5px;
  transition: 300ms;
  `
const VerticalFlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  transition: 200ms;
`
const HorrizontalFlexWrapper = styled.div`
  display: flex;
  margin: auto;
`
const VerticalFlexItem = styled.div`
  margin: 10px;
  width: 100%;
`
const contentHeader = css`
  font-size: 14px;
  opacity: 50%;
  margin: 0;
`
const contentText = css`
  font-size: 15px;
  font-weight: 200;
  margin: 0;
`
const contentLink = css`
  color: #232129;
  text-decoration: none;
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
`
const LinkArrow = styled.p`
  color: #232129;
  font-size: 12px;
  font-weight: 200;
  margin: 0;
  transition: 300ms;
`
const AnimateLinkContainer = styled.div`
  display: flex;
  margin: 0;
  :hover {
    ${LinkArrow} {
      transform: translate(5px);
    };
  }
`

const Contact = () => {
  const [menuIsOpen, setMenuIsOpen] = React.useState(false);
  const openMenu = () => {
    setMenuIsOpen(true);
  };
  const closeMenu = () => {
    setMenuIsOpen(false);
  };
  const handleMenuClick = () => {
   if (menuIsOpen) {
      closeMenu();
   } else {
      openMenu();
   }
  }

  const breakpoints = useBreakpoint();

  return (
    <div>
      <VerticalFlexWrapper style={{minWidth: '80px'}}>
         <HorrizontalFlexWrapper onClick={handleMenuClick}>
            <p css={logo}>
               contact
            </p>
            <p css={logo} style={menuIsOpen ? {color: "red", marginLeft: '2px'} : {opacity: "30%", marginLeft: '2px'}}>
               *
            </p>
         </HorrizontalFlexWrapper>
         {menuIsOpen ? 
            <div>
               <AnimateLinkContainer>
                  <LinkArrow>
                     &gt;
                  </LinkArrow>
                  <a css={[contentText, contentLink]} style={{margin: "0px 0px 0px 10px"}} href="mailto:kuptiman@gmail.com" rel="noopener noreferrer" target="_blank">
                     EMAIL
                  </a>
               </AnimateLinkContainer>
               <AnimateLinkContainer>
                  <LinkArrow>
                     &gt;
                  </LinkArrow>
                  <a css={[contentText, contentLink]} style={{margin: "0px 0px 0px 10px"}} href="https://www.linkedin.com/in/nawach-kuptimanus-90bb03162/" rel="noopener noreferrer" target="_blank">
                     LINKEDIN
                  </a>
               </AnimateLinkContainer>
               <AnimateLinkContainer>
                  <LinkArrow>
                     &gt;
                  </LinkArrow>
                  <a css={[contentText, contentLink]} style={{margin: "0px 0px 0px 10px"}} href="https://github.com/kuptiman/" rel="noopener noreferrer" target="_blank">
                     GITHUB
                  </a>
               </AnimateLinkContainer>
               <AnimateLinkContainer>
                  <LinkArrow>
                     &gt;
                  </LinkArrow>
                  <a css={[contentText, contentLink]} style={{margin: "0px 0px 0px 10px"}} href="https://twitter.com/kuptiman/" rel="noopener noreferrer" target="_blank">
                     TWITTER
                  </a>
               </AnimateLinkContainer>
            </div>
         : null}
      </VerticalFlexWrapper>
    </div>
  )
}

export default Contact