import * as React from "react";

import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import styled from "@emotion/styled";
import { css } from "@emotion/react";

import "@fontsource/titillium-web";
import "@fontsource/roboto-mono";
import "@fontsource/share-tech-mono";

const HorrizontalFlexWrapperMobile = styled.div`
  display: flex;
  justify-contents: center;
  align-items: center;
  flex-direction: column;
`
const LinkArrow = styled.p`
  font-size: 13px;
  margin: 0;
  transition: 300ms;
  vertical-align: middle;
`
const HorrizontalFlexWrapper = styled.div`
  display: flex;
  margin: auto;
  :hover {
    ${LinkArrow} {
      transform: translate(5px);
    };
  }
`
const HorrizontalFlexItem = styled.div`
  margin: 10px;
`
const contentHeader = css`
  font-size: 14px;
  opacity: 50%;
  margin: 0;
`
const contentText = css`
  font-size: 14px;
  margin: 0;
`
const AnimateLinkContainer = styled.div`
  display: flex;
  align-items: center;
  min-width: 100px;
  margin: 10px;
  :hover {
    ${LinkArrow} {
      transform: translate(5px);
    };
  }
`
const projects = [
  {
    number: 1,
    name: "MOLA DEMO",
    description: "FULL STACK WEB APPLICATION FOR PSYCHOLOGICAL SURVEYING.",
    tags: "#REACT #JAVASCRIPT #EXPRESS #MONGODB",
    transitionDelay: "100",
  },{
    number: 2,
    name: "BLOCKCHAIN PROTOTYPE W/ UNIX SOCKETS",
    description: "SIMPLE CRYPTOCURRENCY TRANSACTION SYSTEM BUILT USING UNIX SOCKETS, EMPLOYING TCP AND UDP CONNECTIONS.",
    tags: "#C++ #UNIX #SOCKET-PROGRAMMING",
    transitionDelay: "150",
  },{
    number: 3,
    name: "SMILEY",
    description: "LIVE CAMERA + GRAPHICS EDITOR PROGRAM WITH MULTIPLE OPENCV MODULES.",
    tags: "#C++ #QT #OPENCV",
    transitionDelay: "200",
  },{
    number: 4,
    name: "ARTIST LANDING PAGE",
    description: "INTERACTIVE ARTIST PROMOTIONAL LANDING WEBSITE.",
    tags: "#GATSBY #REACT #JAVASCRIPT #NODE-JS",
    transitionDelay: "250",
  },{
    number: 5,
    name: "GRUB",
    description: "MOBILE APP FOR VENUES & RESTAURANTS EVENT HOSTING.",
    tags: "#REACT-NATIVE #FIGMA",
    transitionDelay: "300",
  },{
    number: 6,
    name: "LOST & FIND",
    description: "FULL STACK WEB APPLICATION LOST AND FOUND PLATFORM.",
    tags: "#REACT #JAVASCRIPT #FLASK",
    transitionDelay: "350",
  },
];
const MoreProjects = () => {
  const breakpoints = useBreakpoint();
  return (
    <>
      {projects.map((project) =>
        <AnimateLinkContainer 
          style={breakpoints.sm ? {} : {marginLeft: "20px"}} 
          data-sal="slide-up" 
          data-sal-duration="400" 
          data-sal-delay={project.transitionDelay} 
          data-sal-easing="ease" 
          key={project.number}>
          {breakpoints.sm ? null :
            <LinkArrow>
              &gt;
            </LinkArrow>
          }
          <HorrizontalFlexItem style={breakpoints.sm ? {minWidth: "80px", maxWidth: "80px"} : {minWidth: "120px", maxWidth: "120px"}}>
            <p css={[contentText,]} style={breakpoints.sm ? {} : {marginLeft: "10px"}}>{project.name}</p>
          </HorrizontalFlexItem>
          <HorrizontalFlexItem style={{width: "100%"}}>
            <p css={[contentText,]} style={{opacity: "60%"}}>{project.description}</p>
          </HorrizontalFlexItem>
          <HorrizontalFlexItem style={breakpoints.sm ? {minWidth: "80px", maxWidth: "80px"} : {minWidth: "200px", maxWidth: "200px"}}>
            <p css={[contentText,]}>{project.tags}</p>
          </HorrizontalFlexItem>
        </AnimateLinkContainer>
      )}
    </>
  )
}

export default MoreProjects