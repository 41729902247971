import * as React from "react";
import Tilt from 'react-parallax-tilt';
// import { FaLinkedinIn, FaGithub, FaTwitter } from 'react-icons/fa';

import { StaticImage } from "gatsby-plugin-image";
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import styled from "@emotion/styled";
import { css } from "@emotion/react";

import "@fontsource/titillium-web";
import "@fontsource/roboto-mono";
import "@fontsource/share-tech-mono";

import Contact from "../components/contact";
import FeaturedProjects from "../components/featuredProjects";
import ProjectCards from "../components/projectCards";
import MoreProjects from "../components/moreProjects";
import Introduction from "../components/introduction";
import ContactMe from "../components/contactMe";

const pageStyles = css`
  color: #232129;
  background-color: #f4f4f5;
  font-family: Roboto Mono, Roboto, -apple-system, Open Sans, sans-serif, serif;
  margin: 0;
`
const Nav = styled.div`
  position: fixed;
  top: 10px;
  font-family: Titillium Web, Open Sans,-apple-system, Roboto, sans-serif, serif;
  font-size: 20px;
  font-weight: 600;
  user-select: none;
  cursor: pointer;
  transition: 200ms;
`
const logo = css`
  font-size: 24px;
  font-weight: 700;
`
const logoUnderlineHoverAnimation = css`
:after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 1px;
  top: 58px;
  left: 0;
  background-color: #232129;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
  border-radius: 2px;
}
:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}
`
const Content = styled.div`
  max-width: 990px;
  paddingTop: 100px;
  margin: auto;
`
const HeaderWrapper = styled.div`
  max-width: 800px;
  margin: -400px auto 0px auto;
`
const Title = styled.h1`
  font-size: 42px;
  font-weight: 200;
  margin: 0;
  text-transform: uppercase;
`
const Email = styled.h3`
  font-size: 16px;
  font-weight: normal;
  opacity: 50%;
  margin: 0;
`
const NameEmailWrapper = styled.div`
  font-family: Roboto Mono, Roboto, -apple-system, Open Sans, sans-serif, serif;
  text-align: center;
  margin: 12px 0;
`
const contentHeader = css`
  font-size: 14px;
  opacity: 50% !important;
  margin: 0;
`
const contentHeaderSplit = css`
  font-size: 14px;
  opacity: 80% !important;
  margin: 40px 0 0 0;
`
const contentText = css`
  font-size: 12px;
  margin: 0;
  text-align: center;
  text-transform: uppercase;
`
const contentLink = css`
  color: #232129;
  text-decoration: none;
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
  width: 100%;
`
const Footnote = styled.div`
  user-select: none;
`

const IndexPage = () => {
  const breakpoints = useBreakpoint();
  const handleScrollTop = () => {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' })
  }
  return (
    <main css={pageStyles}>
      {/* Nav Bar */}
      {breakpoints.md ? null :
        <>
          <Nav css={[logoUnderlineHoverAnimation]} style={{ left: "60px" }} onClick={handleScrollTop}>
            <p css={[logo]}>kuptiman</p>
          </Nav>
          <Nav css={[logoUnderlineHoverAnimation]} style={{ right: "60px" }}>
            <Contact />
          </Nav>
        </>
      }

      {/* Main */}
      <Content>
        <HeaderWrapper style={breakpoints.sm ? { margin: "-200px auto 0px auto" } : {}}>
          <Tilt
            tiltReverse={false}
            glareEnable={true}
            glareColor={"#fffaf7"}
            glareMaxOpacity={0.2}
            tiltMaxAngleX={5}
            tiltMaxAngleY={2}>
            <StaticImage
              src="../images/passporteditkuptimanv3.png"
              alt="Nawach Kuptimanus Passport Photo"
              placeholder="blurred"
              layout="constrained"
              draggable={false}
            />
          </Tilt>
        </HeaderWrapper>
        <NameEmailWrapper style={breakpoints.sm ? { margin: "10px 0" } : {}}>
          <Title style={breakpoints.sm ? { fontSize: "34px" } : { textAlign: "left" }}>
            Yoon Kuptimanus
          </Title>
          {/* <Email style={{textAlign: "left"}}>
              kuptiman@gmail.com
            </Email> */}
        </NameEmailWrapper>

        <Introduction />
        {/* {breakpoints.sm ? <IntroductionMobile/> : <Introduction/>} */}

        <h2 css={contentHeaderSplit} style={breakpoints.md ? { textAlign: "center" } : null} data-sal="slide-up" data-sal-duration="1000" data-sal-delay="300" data-sal-easing="ease">
          CREATIONS
        </h2>
        <ProjectCards />

        <h2 css={contentHeaderSplit} style={breakpoints.md ? { textAlign: "center" } : null} data-sal="slide-up" data-sal-duration="800" data-sal-delay="300" data-sal-easing="ease">
          ARCHIVED CREATIONS
        </h2>
        <MoreProjects />

        {/* <h2 css={contentHeaderSplit}data-sal="slide-up" data-sal-duration="800" data-sal-delay="300" data-sal-easing="ease">
          CONTACT ME
        </h2>
        <ContactMe/> */}

      </Content>
      <Footnote>
        {/* <p css={contentText} style={{marginTop: "20px", opacity: "50%"}}>
            obligatory
        </p> */}
        <p css={contentText} style={{ paddingBottom: "10px" }}>
          Made with &#60;3 by Yoon Kuptimanus
        </p>
      </Footnote>
    </main>
  )
}

export default IndexPage

export const Head = () => <title>YOON KUPTIMANUS</title>